<template>
  <div class="experience_container">
    <div class="tab">
      <ul>
        <li
          v-on:mouseenter="change(index)"
          v-bind:class="currentIndex == index ? 'active' : ''"
          :key="item.id"
          v-for="(item, index) in list"
        >
          {{ item.title }}
        </li>
      </ul>
      <div
        v-bind:class="currentIndex == index ? 'current' : ''"
        :key="item.id"
        v-for="(item, index) in list"
      >
        <img v-bind:src="item.path" />
      </div>
    </div>
    <div class="div2">
      <span class="span">试用体验</span>

      <label class="name">姓名<font style="color: red">*</font></label
      ><input
        class="input"
        type="text"
        name="userName"
        v-model="user.userName"
      /><br />
      <label class="name">手机<font style="color: red">*</font></label
      ><input
        class="input"
        type="text"
        name="userPhone"
        v-model="user.userPhone"
      /><br />
      <label class="name1">微信 </label
      ><input
        class="input"
        type="text"
        name="userWx"
        v-model="user.userWx"
      /><br />
      <label class="name1">公司 </label
      ><input
        class="input"
        type="text"
        name="userWork"
        v-model="user.userWork"
      /><br />
      <label class="name1">需求 </label
      ><input
        class="input"
        type="text"
        name="userNeed"
        v-model="user.userNeed"
      /><br />
      <div class="text">
        <span>感谢您对枫林云的信任与支持,我们的专业人员将在</span>
        <span>24小时与你联系,请你留意接听，您也可以主动联系</span>
        <span><font style="color: red">18928499499</font>,立刻了解</span>
      </div>
      <button class="btn" @click="postData">提交</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        userName: "",
        userPhone: "",
        userWx: "",
        userWork: "",
        userNeed: "",
      },
      userName: "",
      currentIndex: 0, // 选项卡当前的索引
      list: [
        {
          id: 1,
          title: "枫林营销云",
          path: require("../../assets/images/1.jpg"),
        },
        {
          id: 2,
          title: "枫林智慧案场",
          path: require("../../assets/images/2.png"),
        },
        {
          id: 3,
          title: "枫林云经纪人",
          path: require("../../assets/images/3.jpg"),
        },
      ],
    };
  },
  methods: {
    //提交表单
    async postData() {
      const response = await fetch(
        "https://yunke-api.dichanyun.cn/registration/saveRegistrationInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.user),
        }
      );
      return response.json();
    },
    change: function (index) {
      this.currentIndex = index;
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.el-input__inner {
  width: 200px;
  background-color: rgba(248, 246, 244, 0.938);
  height: 40px;
}
.experience_container {
  width: 1921px;
  height: 1000px;
  background-image: url("../../assets/试用 -枫林智慧案场.png");
  position: relative;
  background-size:100% 100%;
}
.tab {
  width: 1921px;
  height: 200px;
  display: flex;
}
.d1 {
  line-height: 50px;
  text-align: center;
  width: 200px;
  height: 50px;
  background-color: white;
  color: #0b74d6;
  font-weight: bold;
  font-size: 20px;
}
.d2 {
  line-height: 50px;
  text-align: center;
  width: 200px;
  height: 50px;
  background-color: darkgray;
  color: white;
  opacity: 0.8;
  border-right: 1px solid white;
  font-weight: bold;
  font-size: 20px;
}
.div2 {
  width: 476px;
  height: 637px;
  position: absolute;
  left: 251px;
  top: 252px;
  background-color: white;
  border-radius: 31px;
}
.span {
  width: 476px;
  height: 28px;
  display: block;
  text-align: center;
  color: black;
  font-size: 25px;
  font-weight: bold;
  margin-top: 33px;
}
.name {
  color: black;
  font-size: 22px;
  margin-right: 10px;
}
.name1 {
  color: black;
  font-size: 22px;
  margin-right: 10px;
}
.input {
  width: 300px;
  height: 44px;
  margin-top: 28px;
  background-color: #f2f2f2;
  border: 0;
  outline: none;
}
.text {
  width: 370px;
  height: 64px;
  margin: 0 auto;
  margin-top: 29px;
}
.text span {
  color: black;
  font-size: 15px;
  font-weight: bold;
}
.btn {
  width: 341px;
  height: 58px;
  background-color: #466bf5;
  border-radius: 29px;
  color: white;
  border: 0;
  margin-top: 21px;
}

.tab ul {
  width: 1700px;
  margin: 0 auto;
  margin-top: 87px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.tab ul li {
  flex-wrap: nowrap;
  width: 380px;
  height: 80px;
  line-height: 80px;
  list-style: none;
  background-color: #4273f6;
  font-size: 28px;
  cursor: pointer;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.tab *.active {
  color: black;
  background-color: white;
  width: 700px;
  height: 100px;
  line-height: 100px;
  font-size: 32px;
  font-weight: bold;
}
.tab div {
  position: absolute;
  display: none;
  top: 360px;
  right: 382px;
  width: 169px;
  height: 169px;
}
.tab div img {
  width: 100%;
  height: 100%;
}
.tab div.current {
  display: block;
}
</style>
